import React, { useState } from 'react';
import logoImg from "./../images/OClogo.svg";


const NavElements = () => {

  return (
    <ul className="invisible flex items-center hidden space-x-8 lg:flex">
      <li>
        <a
          href="/calculator"
          aria-label="Our product"
          title="Our product"
          className="font-light text-sm tracking-wide text-ocPink transition-colors duration-200 hover:text-teal-accent-400 bg-growing-underline"
        >
          Buy a home
        </a>
      </li>
      <li>
        <a
          href="/"
          aria-label="Our product"
          title="Our product"
          className="font-light text-sm tracking-wide text-ocPink transition-colors duration-200 hover:text-teal-accent-400 bg-growing-underline"
        >
          Rates
        </a>
      </li>
      <li>
        <a
          href="/"
          aria-label="Product pricing"
          title="Product pricing"
          className="font-light text-sm tracking-wide text-ocPink transition-colors duration-200 hover:text-teal-accent-400 bg-growing-underline"
        >
          About Us
        </a>
      </li>
      <li>
        <a
          href="/"
          aria-label="About us"
          title="About us"
          className="font-light text-sm tracking-wide text-ocPink transition-colors duration-200 hover:text-teal-accent-400 bg-growing-underline"
        >
          Contact
        </a>
      </li>
      <li>
        <a
          href="#"
          className="bg-ocTeal inline-flex items-center justify-center px-4 py-2 text-sm font-light tracking-wide text-white transition-colors duration-500 rounded-full shadow-md hover:bg-ocPink hover:text-gray-900 outline-transparent hover:outline hover:outline-ocPink hover:outline-offset-2 hover:outline-2"
          aria-label="Sign in"
          title="Sign in"
        >
          Sign In
        </a>
      </li>
    </ul>
  )
}

export const SmallNav = (props) => {

  const smallNavVisibleCss = props.smallNavVisible ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none";

  return (
    <div className={`fixed transition duration-200 z-20 top-0 w-full h-20 bg-ocBlue drop-shadow-lg ${smallNavVisibleCss}`}>
        <div className={`px-4 transition duration-200 h-20 flex items-center justify-between mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8`}>
            <a
              href="/"
              aria-label="Company"
              title="Company"
              className="inline-flex items-center"
            >
              <img className={`h-14 mt-2 origin-[0%_50%] scale-50`} src={logoImg} alt="Owned Collectively" />
            </a>
            <NavElements />
            <div className="lg:hidden">
              <button
                aria-label="Open Menu"
                title="Open Menu"
                className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline"
                onClick={() => props.setIsMenuOpen(true)}
              >
                <svg className="w-5 text-gray-600 transition duration-300 hover:text-gray-200" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                  />
                </svg>
              </button>
            </div>
        </div>
      </div>
  )

}

export const Nav = (props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
     <>
      <div className={`absolute z-10 top-0 w-full bg-gradient-to-b from-ocBlue h-32`}></div>
      <div className={`absolute z-20 top-0 w-full h-32`}>
        <div className={`px-4 h-32 flex items-center justify-between mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8`}>
            <a
              href="/"
              aria-label="Company"
              title="Company"
              className="inline-flex items-center"
            >
              <img className={`h-14 mt-2`} src={logoImg} alt="Owned Collectively" />
            </a>
            <NavElements />
            <div className="lg:hidden">
              <button
                aria-label="Open Menu"
                title="Open Menu"
                className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline"
                onClick={() => setIsMenuOpen(true)}
              >
                <svg className="w-5 text-gray-600 transition duration-300 hover:text-gray-200" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                  />
                </svg>
              </button>
            </div>
        </div>
      </div>
      <SmallNav 
        smallNavVisible={props.scrolled}
        setIsMenuOpen={setIsMenuOpen}
      />
      </>
    );
  };


  // {isMenuOpen && (
  //   <div class="absolute top-0 left-0 w-full">
  //     <div class="p-5 bg-white border rounded shadow-sm">
  //       <div class="flex items-center justify-between mb-4">
  //         <div>
  //           <a
  //             href="/"
  //             aria-label="Company"
  //             title="Company"
  //             class="inline-flex items-center"
  //           >
  //             <img class={"h-14 mt-2"} src={logoImg} alt="Owned Collectively" />
  //           </a>
  //         </div>
  //         <div>
  //           <button
  //             aria-label="Close Menu"
  //             title="Close Menu"
  //             class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
  //             onClick={() => setIsMenuOpen(false)}
  //           >
  //             <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
  //               <path
  //                 fill="currentColor"
  //                 d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
  //               />
  //             </svg>
  //           </button>
  //         </div>
  //       </div>
  //       <nav>
  //         <NavElements />
  //       </nav>
  //     </div>
  //   </div>
  // )}